export default {
  SET_SELECTED_ACCOUNT_TABLE_ROW_DATA(state, payload) {
    state.selectedAccountTableRowData = payload;
  },
  SET_SELECTED_CONTACT_TABLE_ROW_DATA(state, payload) {
    state.selectedContactTableRowData = payload;
  },
  SET_SELECTED_STORE_TABLE_ROW_DATA(state, payload) {
    state.selectedStoreTableRowData = payload;
  },
  SET_SELECTED_OPPORTUNITY_TABLE_ROW_DATA(state, payload) {
    state.selectedOpportunityTableRowData = payload;
  },
  SET_SELECTED_EVENT_TABLE_ROW_DATA(state, payload) {
    state.selectedEventTableRowData = payload;
  },
  SET_SELECTED_TABS(state, payload) {
    state.selectedTabs = payload;
  },
  SET_SELECTED_ACCOUNT_TABS(state, payload) {
    state.selectedAccountTabs = payload;
  },
  SET_OPPORTUNITY_DATE_PICKER(state, payload) {
    state.opportunityDatePicker = payload;
  },
  SET_PAGINATION_DATA(state, payload) {
    state.paginationData = payload;
  },
  // SET_TABLE_LISTS(state, payload) {
  //   if (payload.data.length === 0)
  //     state.tableLists[payload.listType + 'List'] = [];
  //   else {
  //     if (payload.prepend) {
  //       state.tableLists[payload.listType + 'List'].unshift(payload.data);
  //     } else {
  //       state.tableLists[payload.listType + 'List'].push(...payload.data);
  //     }
  //   }
  // },
  // CLEAR_TABLE_LISTS(state, payload) {
  //   state.tableLists[payload] = [];
  // },
  // SET_SUB_TABS(state, payload) {
  //   state.subTabs = payload;
  // },
  SET_SEARCH_KEYWORD(state, payload) {
    state.searchKeyword[payload.listName] = payload.data;
  },
  SET_FILTERS(state, payload) {
    state.filters[payload.listName] = payload.filteredData;
  },
  SET_CUSTOM_DATE_RANGE(state,payload){
    state.filters.opportunityList["query.filter.equal.customDateRange"] = payload;
  },
  // SET_SUB_TABS_LIST(state, payload) {
  //   // payload = {
  //   //   routeName: 'accountSubTabs',
  //   //   listName: 'contactList',
  //   //   data: array,
  //   // }
  //   state.subTabs[payload.routeName][payload.listName] = payload.data;
  // },
  SET_ACCOUNT_LIST_IN_CONTACT_SUB_TABS(state, payload) {
    state.accountListInContactSubTabs = payload;
  },
  SET_SUB_SEARCH_KEYWORD(state, payload) {
    // payload = {
    //   routeName: 'accountSubTabs',
    //   listName: 'contactSearch',
    //   data: string,
    // }
    state.subSearchKeywords[payload.routeName][payload.listName] = payload.data;
  },
  // CLEAR_SUB_TABS_TABLE_LISTS(state, payload) {
  //   // payload = {
  //   //   routeName: 'accountSubTabs',
  //   //   listName: 'contactList',
  //   // }
  //   state.subTabs[payload.routeName][payload.listName] = [];
  // },
  // CLEAR_ALL_SUB_TABS_LISTS(state) {
  //   state.subTabs = {
  //     accountSubTabs: {
  //       contactList: [],
  //       storeList: [],
  //       subscriptionList: [],
  //       subscriptionLogList: [],
  //     },
  //     contactSubTabs: {
  //       storeList: [],
  //     },
  //   };
  // },
  SET_TRIAL_END_TIME(state, payload) {
    const newSubStoreList = state.subTabs.accountSubTabs.storeList.map(
      (store) => {
        return {
          ...store,
          trialEndTime: payload,
        };
      }
    );
    state.subTabs.accountSubTabs.storeList = newSubStoreList;
  },
  UPDATE_ACCOUNT(state, payload) {
    let accountIndex = state.tableLists.accountList.findIndex(
      (acc) => acc.accountId === payload.accountId
    );
    state.tableLists.accountList[accountIndex] = payload;
  },
  UPDATE_CONTACT(state, payload) {
    let contactIndex = state.tableLists.contactList.findIndex(
      (con) =>
        con.accountId === payload.accountId &&
        con.contactId === payload.contactId
    );
    state.tableLists.contactList[contactIndex] = payload;
  },
  UPDATE_STORE(state, payload) {
    let storeIndex = state.tableLists.storeList.findIndex(
      (con) =>
        con.accountId === payload.accountId && con.storeId === payload.storeId
    );
    state.tableLists.storeList[storeIndex] = payload;
  },

  SET_SORTING(state, payload) {
    // payload = {
    //   listName: 'accountList',
    //   column: 'account_name',
    //   value: 'asc',
    // }
    state.sort[payload.listName]['query.sorting.column'] = payload.column;
    state.sort[payload.listName]['query.sorting.value'] = payload.value;
  },

  SET_ACCOUNT_DETAILS(state, payload) {
    state.accountDetails = payload;
  },

  SET_ACCOUNT_MANAGER_LIST(state, payload) {
    state.accountManagerList = payload;
  },
  SET_ACCOUNT_SOURCE_TYPE_LIST(state, payload) {
    state.accountSourceTypeMap = payload;
  },
  SET_SPECIFIC_ACCOUNT_SOURCE_TYPE(
    state,
    { oldAccountSourceType, newAccountSourceType }
  ) {
    let index = state.accountSourceTypeMap.findIndex(
      (element) => element.type === oldAccountSourceType.type
    );
    state.accountSourceTypeMap[index] = newAccountSourceType;
  },
  SET_CONTACT_TYPES(state, payload) {
    state.contactTypes = payload;
  },
  SET_TIMEZONE_LIST(state, payload) {
    state.timezoneList = payload;
  },

  SET_IS_ADD_NEW_ACCOUNT(state, payload) {
    state.isAddNewAccount = payload;
  },
  SET_IS_ADD_NEW_CONTACT(state, payload) {
    state.isAddNewContact = payload;
  },
  SET_IS_ADD_NEW_OPPORTUNITY(state, payload) {
    state.isAddNewOpportunity = payload;
  },
  SET_IS_ADD_NEW_EVENT(state, payload) {
    state.isAddNewEvent = payload;
  },
  //export
  SET_DOWNLOAD(state, { evaStoreId, download, willReset = false }) {
    if (willReset) {
      state.download = {
        ...state.download,
        [evaStoreId]: null,
      };
      delete state.download[evaStoreId];
    } else {
      state.download = {
        ...state.download,
        [evaStoreId]: {
          ...state.download[evaStoreId],
          ...download,
        },
      };
    }
    localStorage.setItem(
      `download-account-list-bulk`,
      JSON.stringify(state.download)
    );
  },
  SET_UPLOAD(state, { evaStoreId, upload, willReset = false }) {
    if (willReset) {
      state.upload = {
        ...state.upload,
        [evaStoreId]: null,
      };
      delete state.upload[evaStoreId];
    } else {
      state.upload = {
        ...state.upload,
        [evaStoreId]: {
          ...state.upload[evaStoreId],
          ...upload,
        },
      };
    }
    localStorage.setItem(
      `upload-account-list-bulk`,
      JSON.stringify(state.upload)
    );
  },
  SET_SHOW_ACCOUNT_DETAIL_BUTTON(state, payload) {
    state.showAccountDetailButton = payload;
  },
};
